import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import 'styles/globals.css';
import GlobalStyle from 'styles/globalstyles';

const theme: DefaultTheme = {
  colors: {
    primary: '#111',
    secondary: '#0070f3',
  },
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} />
        <Toaster />
      </ThemeProvider>
    </>
  );
}
